<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">
            <strong>Informacje</strong><br />
            <a href="mailto:info@paradiso.org.pl">info@paradiso.org.pl</a>
          </p>
          <p class="page-text__text">
            <strong>Rzecznik prasowy</strong><br />
            <a href="mailto:rzecznik-prasowy@paradiso.org.pl">rzecznik-prasowy@paradiso.org.pl</a>
          </p>
          <p class="page-text__text">
            <strong>Media</strong><br />
            <a href="mailto:media@paradiso.org.pl">media@paradiso.org.pl</a>
          </p>
          <p class="page-text__text">
            <strong>Rejestracja - administrator</strong><br />
            <a href="mailto:administrator@paradiso.org.pl">administrator@paradiso.org.pl</a>
          </p>
          <p class="page-text__text">
            <strong><a target="_blank" href="https://www.facebook.com/paradisogostyn">Facebook</a></strong>
          </p>
          <p class="page-text__text">
            <strong><a target="_blank" href="https://www.youtube.com/channel/UCMoyNKM513phEkJGVFLr-qw">YouTube</a></strong>
          </p>
          <p class="page-text__text">
            <strong><a target="_blank" href="https://www.instagram.com/paradiso_gostyn/?fbclid=IwAR2eZk7KSdisNy7rJEOlY7gBrHwM_YF8F88V-le6d0nquHCeSNihtzy7hKM">Instagram</a></strong>
          </p>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
  export default {
    components: {},
  };
</script>
<style lang="scss" scoped></style>
